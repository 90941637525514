.shares-section {
	background-image: url("/src/images/macbook-shares.webp"),
		url("/src/images/svg/bg-shapes-blue-shares-390.svg");
	background-repeat: no-repeat, no-repeat;
	background-position: calc(0% - 3px) calc(0% + 208px),
		calc(50%) calc(50% - 148px);
	background-size: 616px, 1012px;
	padding-top: 128px;
	margin-top: -128px;
	@include mq(tablet-S) {
		background-image: url("/src/images/macbook-shares.webp"),
			url("/src/images/svg/bg-shapes-blue-shares-768.svg");
		background-repeat: no-repeat, no-repeat;
		background-position: calc(50% + 440px) calc(50% - 227px),
			calc(50% + 44px) calc(50% + 16px);
		background-size: 752px, auto;
		padding-top: 0;
		margin-top: 0;
		padding-bottom: 480px;
		margin-bottom: -480px;
	}
	@include mq(tablet-L) {
		background-position: calc(50% + 392px) calc(50% - 227px),
			calc(50% + 116px) calc(50% - 40px);
		background-size: 752px, 1664px;
	}
	@include mq(desktop) {
		background-image: url("/src/images/macbook-shares.webp");
		background-repeat: no-repeat;
		background-position: calc(50% + 416px) calc(50% - 14px),
			calc(50% + 44px) calc(50% + 16px);
		background-size: 816px;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.shares-container {
	@include flex(flex, center, stretch);
	flex-direction: column;
	padding-top: 476px;
	padding-bottom: $XL;
	@include mq(tablet-S) {
		padding-top: $L;
		padding-bottom: $L;
	}
}

.shares-description-container {
	@include mq(tablet-S) {
		max-width: 416px;
	}
	@include mq(tablet-L) {
		max-width: 490px;
	}
	@include mq(desktop) {
		max-width: 560px;
	}
}

// .shares-description-subheader {
//   margin-bottom: $M;
//   text-wrap: balance;
// }

.shares-description-list {
	@include flex(flex, center, flex-start);
	flex-direction: column;
	gap: $S;
	margin-bottom: $L;
}

.shares-description-item {
	@include flex(flex, center, flex-start);
	gap: $XS;
}

.shares-description-icon {
	stroke: $accentColor500;
}
