.products-section {
	background-image: url("/src/images/svg/bg-shape-products-390.svg");
	background-repeat: no-repeat;
	background-position: calc(50% + 304px) calc(50% - 24px);
	@include mq(tablet-S) {
		background: none;
	}
}

.products-container {
	@include flex(flex, center, stretch);
	flex-direction: column;
	gap: $L;
	padding-top: $XL;
	padding-bottom: $XL;
	@include mq(tablet-S) {
		gap: $XL;
		padding-bottom: $L;
	}
	@include mq(tablet-L) {
		flex-direction: row;
		gap: $M;
		padding-top: $L;
	}
	@include mq(desktop) {
		align-items: flex-end;
		padding-top: $XL;
		padding-bottom: $XL;
	}
}

.products-buttons-list {
	@include flex(flex, center, stretch);
	flex-direction: column;
	gap: $M;
	@include mq(tablet-S) {
		flex-direction: row;
		flex-wrap: wrap;
	}
	@include mq(tablet-L) {
		flex-basis: calc((100% - 1 * $M) / 2);
	}
}

.products-buttons-item {
	@include mq(tablet-S) {
		flex-basis: calc((100% - 3 * $M) / 4);
	}
	@include mq(tablet-L) {
		flex-basis: calc((100% - 1 * $M) / 2);
		height: calc((100% - 1 * $M) / 2);
	}
}

.products-button {
	display: block;
	width: 100%;
	font-family: $secondaryFontFamily;
	color: $neutralColor600;
	text-transform: uppercase;
	@include font(800, 1rem, 1.5rem, 0);
	border-radius: $S;
	border: none;
	padding: $M 0;
	background-color: $neutralColor0Transparency600;
	box-shadow: $shadowElevationMedium;
	transition: color $mainTransitionDuration $mainTransitionTimingFunction,
		background-color $mainTransitionDuration $mainTransitionTimingFunction,
		box-shadow $mainTransitionDuration $mainTransitionTimingFunction;
	&:hover,
	&:focus {
		cursor: pointer;
		color: $primaryColor100;
		background-color: $primaryColor950;
		box-shadow: $shadowElevationLow;
	}
	@include mq(tablet-S) {
		padding: $L 0;
	}
	@include mq(tablet-L) {
		height: 100%;
	}
	&.active {
		color: $primaryColor100;
		background-color: $primaryColor950;
		& .shares-button-icon-accent,
		& .bonds-button-icon-accent,
		& .repo-button-icon-accent {
			fill: $primaryColor700;
		}
		& .shares-button-icon-neutral,
		& .shares-button-icon-base,
		& .bonds-button-icon-lines,
		& .bonds-button-icon-seal-decoration,
		& .bonds-button-icon-paper,
		& .repo-button-icon-displayed-charts,
		& .repo-button-icon-computers {
			fill: $primaryColor100;
		}
		& .depositary-button-icon-base,
		& .depositary-button-icon-columns {
			stroke: $primaryColor100;
		}
		& .depositary-button-icon-accent-stroke {
			stroke: $primaryColor700;
		}
		& .depositary-button-icon-accent-fill-stroke {
			fill: $primaryColor700;
			stroke: $primaryColor700;
		}
	}
}

.shares-button-icon,
.bonds-button-icon,
.depositary-button-icon,
.repo-button-icon {
	margin: 0 auto $XS;
}

.shares-button-icon-base,
.bonds-button-icon-lines,
.bonds-button-icon-seal-decoration,
.repo-button-icon-displayed-charts {
	fill: $neutralColor500;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction;
}

.shares-button-icon-accent,
.bonds-button-icon-accent,
.repo-button-icon-accent {
	fill: $primaryColor400;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction;
}

.shares-button-icon-neutral,
.bonds-button-icon-paper,
.repo-button-icon-computers {
	fill: $neutralColor600;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction;
}

.depositary-button-icon-columns {
	stroke: $neutralColor500;
	transition: stroke $mainTransitionDuration $mainTransitionTimingFunction;
}

.depositary-button-icon-base {
	stroke: $neutralColor600;
	transition: stroke $mainTransitionDuration $mainTransitionTimingFunction;
}

.depositary-button-icon-accent-stroke {
	stroke: $primaryColor400;
	transition: stroke $mainTransitionDuration $mainTransitionTimingFunction;
}

.depositary-button-icon-accent-fill-stroke {
	fill: $primaryColor400;
	stroke: $primaryColor400;
	transition: fill $mainTransitionDuration $mainTransitionTimingFunction,
		stroke $mainTransitionDuration $mainTransitionTimingFunction;
}

.shares-button {
	&:hover,
	&:focus {
		& .shares-button-icon-base,
		& .shares-button-icon-neutral {
			fill: $primaryColor100;
		}
		& .shares-button-icon-accent {
			fill: $primaryColor700;
		}
	}
}

.bonds-button {
	&:hover,
	&:focus {
		& .bonds-button-icon-lines,
		& .bonds-button-icon-seal-decoration,
		& .bonds-button-icon-paper {
			fill: $primaryColor100;
		}
		& .bonds-button-icon-accent {
			fill: $primaryColor700;
		}
	}
}

.depositary-button {
	&:hover,
	&:focus {
		& .depositary-button-icon-base,
		& .depositary-button-icon-columns {
			stroke: $primaryColor100;
		}
		& .depositary-button-icon-accent-stroke {
			stroke: $primaryColor700;
		}
		& .depositary-button-icon-accent-fill-stroke {
			fill: $primaryColor700;
			stroke: $primaryColor700;
		}
	}
}

.repo-button {
	&:hover,
	&:focus {
		& .repo-button-icon-displayed-charts,
		& .repo-button-icon-computers {
			fill: $primaryColor100;
		}
		& .repo-button-icon-accent {
			fill: $primaryColor700;
		}
	}
}

.products-description-container {
	height: 0;
	opacity: 0;
	overflow: hidden;
	transform-origin: top left;
	transform: translateX(-8px);
	transition: opacity $doubleTransitionDuration $mainTransitionTimingFunction,
		transform $doubleTransitionDuration $mainTransitionTimingFunction;
	@include mq(tablet-S) {
		max-width: 662px;
	}
	@include mq(tablet-L) {
		flex-basis: calc((100% - 1 * $M) / 2);
		padding-left: $M;
	}
	&.visible {
		transform: translateX(0);
		height: auto;
		opacity: 1;
	}
}

.products-description-subheader {
	margin-bottom: $S;
}

.products-description-text {
	margin-bottom: 40px;
	max-width: 460px;
}
