@mixin font($fw, $fs, $lh, $ls) {
  font-weight: $fw;
  font-size: $fs;
  line-height: $lh;
  letter-spacing: $ls;
}

@mixin flex($df, $jc, $ai) {
  display: $df;
  justify-content: $jc;
  align-items: $ai;
}

@mixin mq($range) {
  $mobile: 480px;
  $tablet-S: 768px;
  $tablet-L: 1024px;
  $desktop: 1200px;

  @if $range == mobile-only {
    @media screen and (max-width: ($tablet-S - 0.02)) {
      @content;
    }
  } @else if $range == mobile {
    @media screen and (min-width: $mobile) {
      @content;
    }
  } @else if $range == tablet-S-only {
    @media screen and (min-width: $tablet-S) and (max-width: #{$tablet-L - 0.02}) {
      @content;
    }
  } @else if $range == tablet-S {
    @media screen and (min-width: $tablet-S) {
      @content;
    }
  } @else if $range == tablet-L-only {
    @media screen and (min-width: $tablet-L) and (max-width: #{$desktop - 0.02}) {
      @content;
    }
  } @else if $range == tablet-L {
    @media screen and (min-width: $tablet-L) {
      @content;
    }
  } @else if $range == desktop {
    @media (min-width: $desktop) {
      @content;
    }
  } @else if $range == retina {
    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      @content;
    }
  } @else if $range == notDesktop {
    @media screen and (max-width: #{$desktop - 0.02}) {
      @content;
    }
  }
}
