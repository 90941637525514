a,
h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  display: block;
}

button {
  padding: 0;
}
